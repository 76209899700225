import { Component, OnInit } from '@angular/core';
import { SolutionDetail } from '../model/solution.detail';

const DETAILS: SolutionDetail[] = [
  {
    image: "",
    title: "Privacy Policy",
    template:`
    <h6 class="card-text">We value you as a customer and respect your right to privacy. We know that you purchase our products and services because you trust that we stand behind our promises. We pledge our commitment to treat your information responsibly. We created this privacy policy to show you that we are working hard to protect your privacy.</h6>

    <h6 class="card-text"><b>Information sharing and opt out</b></h6>
    <h6 class="card-text">We do not sell your personal information to anyone for any reason. We do not share it, except for the reasons described below, which are permitted by federal and state law. Therefore, there is no need for you to opt out. If we change our policy, we will tell you and give you a chance to opt out before we share your information.</h6>

    <h6 class="card-text"><b>Confidentiality and security</b></h6>
    <h6 class="card-text">We use physical and technical safeguards to protect your information. We restrict access to your information to those who need it to perform their jobs. Third-party business partners are bound by law to use the information only for our purposes. They may not disclose it or use it in any other way. We comply with all data security laws.</h6>

    <h6 class="card-text"><b>Collecting your personal information</b></h6>
    <h6 class="card-text">We collect personal information about you from different sources. For example, we collect information you send us on applications and forms. We also collect information from your transactions with our sister companies, others, or us. We may collect information from a consumer-reporting agency or a medical provider. This collection depends on the product or service you request.</h6>

    <h6 class="card-text"><b>Disclosing your personal information</b></h6>
    <h6 class="card-text">We do not sell your information to anyone. We may disclose this information to a business that carries out services and marketing for us. We may disclose your information as required or permitted by law. We may disclose your information for a legal or regulatory purpose. These include the following types of information.</h6>
   <ul>
    <li>We may disclose information we receive from you on applications or other forms. This may include your name, address, beneficiaries, Social Security number and family member information. This may also include assets, income, and the property address and value.</li>
    <li>We may disclose information from your transactions with our sister companies, or us. This may include your account balance, policy coverage, and payment history. This may also include premium paid, preferences, claims, and purchase method.</li>
    <li>We may disclose information we receive from a consumer-reporting agency or other report. This may include, your credit report, motor vehicle and driver data. This may also include medical and employment data, loss history reports, and other driver data. This disclosure depends on the products you select.</li>
    <li>We may disclose information to companies that perform marketing services on our behalf. As permitted by law, we may disclose some information to other financial institutions with which we have joint marketing agreements.</li>
    </ul>

    <h6 class="card-text"><b>Using your medical information</b></h6>
    <h6 class="card-text">We sometimes must collect medical information to provide you a product or to pay a claim. We do not use or share it for any purpose, except the following:</h6>
    <ul>
    <li>Underwriting insurance</li>
    <li>Administering your policy, account, or claim</li>
    <li>As required or permitted by law</li>
    <li>As otherwise authorized by you</li>
    <li>Internet Policies</li>
    </ul>

    <h6 class="card-text"><b>What information does Apgar & Bullard Insurance Services collect from Web sites?</b></h6>
    <h6 class="card-text">Apgar & Bullard Insurance Services may collect the information you provide when you:</h6>
    
    <ul>
    <li>Request information from the Apgar & Bullard Insurance Services Web site?</li>
    <li>Use online tools and calculators</li>
    <li>Apply online for products</li>
    <li>Subscribe to online services</li>
    <li>Complete an online form</li>
    <li>Conduct transactions online</li>
    <li>Apply for a job online</li>
    </ul>

    <h6 class="card-text">Apgar & Bullard Insurance Services contracts with certain unaffiliated business partners who help us deliver online products and services. They may keep the information you provide. Other online activities are listed below.</h6>

    
    <h6 class="card-text"><b>Visitor Data</b></h6>

    <h6 class="card-text">Apgar & Bullard Insurance Services collects site-aggregate and customer-specific information about the pages you view on our site. We log IP addresses, browser and platform types, domain names, access times and referral data. We also collect information a customer volunteers on surveys and electronic forms. This information is used to improve our Web site.</h6>
    <h6 class="card-text">Apgar & Bullard Insurance Services gathers data on its Web site. This is a standard industry practice and is used to find out how visitors use our site. We collect aggregate information on the public areas of our Web site. We also collect information on an individual basis on areas of our Web site that require visitors to logon using an ID and password. We may collect the following data:</h6>
    
    <ul>
    <li>How many people visit our Web site</li>
    <li>The pages they visit and the features they use</li>
    <li>How long they stay on our Web site</li>
    <li>The Web site people come from before reaching our Web site</li>
    </ul>

    <h6 class="card-text"><b>Use of "Cookies"</b></h6>
    <h6 class="card-text">We store a cookie on your computer when you visit our Web site. A cookie is a tiny piece of information that is required to create and maintain a secure session. Cookies let you request information and do business online. Cookies do not have confidential or personal information. They do not track a user after leaving our Web site. We use a third-party tracking utility, which may set cookies, to analyze how users move within our Web site.
    </h6>

    <h6 class="card-text"><b>Other Online Security</b></h6>
    <h6 class="card-text">We also use a Secure Sockets Layer (SSL). This keeps all information confidential when it is sent between our Web site and your computer.
    </h6>

    <h6 class="card-text"><b>E-mail</b></h6>
    <h6 class="card-text">We keep the e-mail you send to us. If you agree to receive e-mail messages from us, we will keep the information you give us to send e-mail of specific interest to you. If you choose to send us e-mail, we keep your e-mail, your e-mail address, and our reply. Please be careful about the information you give us in e-mail. As with any public Web site, this communication may not be secure.
    </h6>

    <h6 class="card-text"><b>Web Links</b></h6>
    <h6 class="card-text">It is important to note that other companies or organizations may have links on the Apgar & Bullard Insurance Services family of Web sites. We are not responsible for the collection, use, or security of information by the companies or organizations outside of the Apgar & Bullard Insurance Services family of companies. Please read the privacy policy of Web sites reached through the use of links from the Apgar & Bullard Insurance Services family of Web sites.
    </h6>

    <h6 class="card-text"><b>Why does Apgar & Bullard Insurance Services collect information on its Web site?
    </b></h6>

    <h6 class="card-text">Apgar & Bullard Insurance Services or our business partners use the information from our Web sites for many purposes:
    </h6>
    <ul>
    <li>Edits and feedback</li>
    <li>Marketing and promotions</li>
    <li>Analysis of user behavior</li>
    <li>Product development</li>
    <li>Content improvement</li>
    <li>Informing advertisers how many visitors have seen or clicked on their advertisements</li>
    <li>Customize Web site content and layout</li>
    </ul>

    <h6 class="card-text"><b>What happens if Apgar & Bullard Insurance Services changes its privacy policy?</b></h6>
    <h6 class="card-text">If we decide to change our privacy policy, we will post the changes on our homepage. This way you always know what information we collect, how we use it, and under what circumstances we disclose it.
    </h6>
    <h6 class="card-text">If we decide to use your personal information in a different way than for the use that we told you when we collected it, we will notify you by posting a note on our Web site for 30 days.
    </h6>

    <h6 class="card-text"><b>Questions</b></h6>
    <h6 class="card-text">We value our customers and want you to understand how we use the information we collect. Please contact us if you have any questions about our privacy policy.
    </h6>

    <h6 class="card-text">We will provide you a copy of your personal information that we control and can reasonably retrieve. To access your information, you must provide the following:
    </h6>

    <h6 class="card-text">All policy numbers you want to access.</h6>
    <h6 class="card-text">Please sign your request and have your signature notarized. This ensures the identity of the person requesting the information.
    We do not currently charge a fee to cover the cost of providing you with a copy of this information. However, we do reserve the right to charge a small fee in the future.</h6>
    <h6 class="card-text">You may request that we correct your personal information in our files. Please note that we do not control information provided by third parties. So, you will need to contact the third party to correct any information from them.
    Sometimes we obtain your consumer or credit report. If so, you may request the credit-reporting agency's name and address. You may ask the agency to give you a copy of your report.
    </h6>
    <h6 class="card-text"><b>Please send any inquiries to us at:</b></h6>

    <h6 class="card-text">Apgar & Bullard Insurance Services</h6>
    <h6 class="card-text">ATTN: Privacy Department</h6>
    2650 E Imperial Hwy, Ste 205
    Brea, CA 92821
    <h6 class="card-text"></h6 class="card-text">
    <h6 class="card-text">When you write to us, please include your name, address, and policy number, and your agent name and number, if you know it. You can contact your agent to change information that we control, such as your address. Or, you may contact your Apgar & Bullard Insurance Services customer service representative. The phone number is in your policy packet.
    </h6>
    <h6 class="card-text">This privacy statement describes our privacy practices for both current and former customers. We will provide one copy of this notice to joint policy or contract holders. Please share this information with everyone covered by your policy or contract. If you request, we will send more copies of this statement.
    </h6>
    
    <h6 class="card-text">Thank you for choosing Apgar & Bullard Insurance Services. We look forward to building a lifetime relationship with you.</h6>
    
    <ul>
    <li>Allied Property and Casualty Insurance Company</li>
    <li>AMCO Insurance Company</li>
    <li>Depositors Insurance Company</li>
    <li>Nationwide Mutual Insurance Company</li>
    <li>Nationwide Agribusiness Insurance Company</li>
    <li>Colonial County Mutual Insurance Company</li>
    </ul>
    `
  }
  
]

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  details = DETAILS;
  constructor() { }

  ngOnInit() {
  }

}
