import { Component, Input, OnInit } from '@angular/core';
import { SolutionDetail } from '../model/solution.detail';

@Component({
  selector: 'app-solution-detail',
  templateUrl: './solution-detail.component.html',
  styleUrls: ['./solution-detail.component.css']
})
export class SolutionDetailComponent implements OnInit {  
  @Input() detail: SolutionDetail;
  
  constructor(){}

  ngOnInit() {
  }

}
