import { Component, OnInit } from '@angular/core';
import { SolutionDetail } from '../model/solution.detail';

const DETAILS: SolutionDetail[] = [
  {
    image: "",
    title: "Mission Statement:",
    template: `Using industry knowledge acquired over the last 40+ years, we have developed a unique suite of services as our mission to offer a complete Benefit, HR, Workers Compensation, and Payroll solution for small and large businesses.
        We have brought together HR and compliance software, online benefit administration, risk and safety services, and discounted payroll administration, along with extensive access to the most competitive insurance companies to offer a complete solution.
        We offer a singular, simplified solution that allows employers to focus on what
        matters most; running your business.`
  },
  {
    image: "",
    title: "Our primary objectives are:",
    template: `<ul>
          <li>To provide quality products, services, and risk management solutions</li>
          <li>To deliver wide-ranging services and web-based resources</li>
          <li>To establish and maintain ongoing business relationship</li>
      </ul>
    `
  },
  {
    image: "",
    title: "What we provide:",
    template: `Apgar & Bullard’s full-service model includes: benefit administration, payroll services, property & casualty insurance, business safety programs, risk analysis, human resource management, governmental compliance, and financial wellness.`
  },
  {
    image: "",
    title: "Our Business Offices:",
    template: `
    <div class="container">
    <div class="row">
      <div class="col-sm">
        2650 E. Imperial Hwy, Suite 205
      </div>
    </div>
    <div class="row">
      <div class="col-sm">
        Brea, CA 92821
      </div>
    </div>
    <div class="row">
      <div class="col-sm">
        Phone: 714-985-9630
      </div>
    </div>
    <div class="row">
      <div class="col-sm">
        Fax: 714-985-9754
      </div>
    </div>  
    </div>
    `
  }
]

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  details = DETAILS
  constructor() { }

  ngOnInit() {
  }

}
