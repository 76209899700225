import { Solution } from './solution';

export const SOLUTIONS: Solution[] = [
    {
        name: "Benefit Options & Administration",
        description: "Quality, affordable benefits help you retain your valuable employees." ,
        services: [
        "Customized Benefits/Stop-loss Plans",
        "Full Benefit Administration",
        "Online Enrollment and HR Platform",
        "Industry-leading Customer Service"
        ],
        url: "/benefits",
        img: "assets/img/group-benefits.jpg"
    },
    {
        name: "Risk/Insurance Management",
        description: "We’re committed to protecting your business so you can focus on your day-to-day operations. " ,
        services: [
        "Property and Casualty Insurance",
        "Workers Compensation",
        "Claims Management",
        "Worksite Postings",
        "OSHA Compliance",
        "Safety Manuals"
        ],
        url: "/risk",
        img: "assets/img/insurance-by-industry.jpg"
    },
    {
        name: "HR Support & Compliance",
        description: "We manage your Human resources and government compliance obligations." ,
        services: [
        "HR Support Center Available 24/7",
        "Skilled, Certified HR Consultants",
        "Customized Employee Handbooks",
        "ERISA, POP, COBRA Administration"
        ],
        url: "/hr",
        img: "assets/img/insurance-for-your-business.jpg"
    },
    {
        name: "Payroll & Tax Filing Services",
        description: "Access easy solutions to administer payroll and strategically navigate an expanding tax code. " ,
        services: [
        "Online Payroll Processing",
        "Customized Payroll Reports",
        "Tax Filing Services",
        "W-2's and 1095's",
        "Check Production"
        ],
        url: "/payroll",
        img: "assets/img/insurance-for-your-employees.jpg"
    }
    // {
    //     name: "Get a Quote",
    //     description: "Access easy solutions to administer payroll and strategically navigate an expanding tax code. " ,
    //     services: [
        
    //     ],
    //     url: "quote",
    //     img: ""
    // },
    // {
    //     name: "Contact Us",
    //     description: "Access easy solutions to administer payroll and strategically navigate an expanding tax code. " ,
    //     services: [
        
    //     ],
    //     url: "contact",
    //     img: ""
    // }




];
