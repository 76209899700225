import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConnectionService } from '../connection.service';
import { SubjectType } from '../model/subject-type';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
    
	contactForm: FormGroup;
	submitted = false;
	optionsSelect: Array<any>;
	keys = Object.keys;
	subjectType = SubjectType;
	
  constructor(private fb: FormBuilder, private connectionService: ConnectionService) { 
		this.contactForm = fb.group({
			'contactFormName': ['', Validators.required],
			'contactFormEmail': ['', [Validators.required, Validators.email]],
			'contactFormPhone' : ['', [Validators.required, Validators.pattern(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/)]],
			'contactFormBusiness': [''],
			'contactFormSubject': ['', Validators.required],
			'contactFormMessage': [''],
			'contactFormCopy': [''],
			});
	}

   get f() { return this.contactForm.controls; }

   onSubmit(){
		this.submitted = true;

	    // stop here if form is invalid
        if (this.contactForm.invalid) {
            return;
		}
		
		this.connectionService.sendMessage(this.contactForm.value).subscribe(() => {
			alert('Your message has been sent.');
			this.contactForm.reset();
		}, error => {
			console.log('Error', error);
		});
  }
}
