import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-billboard',
  templateUrl: './billboard.component.html',
  styleUrls: ['./billboard.component.css']
})
export class BillboardComponent implements OnInit {
  
  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  
  constructor() { }

  ngOnInit() {
  }
  

}
