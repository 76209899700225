import { NgModule } from '@angular/core';
import { RouterModule , Routes} from '@angular/router';
import { AboutComponent } from './about/about.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { HumanResourcesComponent } from './human-resources/human-resources.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { RiskManagementComponent } from './risk-management/risk-management.component';
import { PayrollComponent } from './payroll/payroll.component';
import { QuoteComponent } from './quote/quote.component';
import { ContactComponent } from './contact/contact.component';
import { PartnerGalleryComponent } from './partner-gallery/partner-gallery.component';
import {PrivacyComponent } from './privacy/privacy.component';
const routes: Routes = [
  {path: '', redirectTo : '/home', pathMatch: 'full'},
  {path: 'home', component: SolutionsComponent},
  {path: 'about', component: AboutComponent},
  {path: 'hr', component: HumanResourcesComponent},
  {path: 'benefits', component: BenefitsComponent},
  {path: 'risk', component: RiskManagementComponent},
  {path: 'payroll', component: PayrollComponent },
  {path: 'quote', component: QuoteComponent },
  {path: 'contact', component: ContactComponent },
  {path: 'partners', component: PartnerGalleryComponent },
  {path: 'privacy', component: PrivacyComponent }
];

@NgModule({
  
  exports: [
    RouterModule
  ],

  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
