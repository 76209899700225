import { Injectable } from '@angular/core'    
@Injectable()    
export class PartnerImageService {    
    allImages = [];    
    
    getImages() {    
        return this.allImages = PartnerImages.slice(0);    
    }
}

const PartnerImages = [
    "Arch.png",
    "ABCBS_Logo.png",
    "CalChoiceLogoHome.gif",
    "Chubb.png",
    "GuardianLogo2.png",
    "ICW_logo_blue.jpg",
    "Kaiser-Permanente-Logo.png",
    "Nova.png",
    "PhiladelphiaInsurancelogo.jpg",
    "UnitedHealthCareLogoNew.png",
    "aetna.jpg",
    "assurant benefits.gif",
    "bsofca.jpg",
    "cna.png",
    "covered cal.jpg",
    "deltadental.jpg",
    "great-american-insurance-group.gif",
    "guard_logo.jpg",
    "healthnet-logo.png",
    "libertymutual.png",
    "logo bhhc.png",
    "mercury-insurance-logo.png",
    "midland NAtional.jpg",
    "ohionational.jpg",
    "rsz_hartfordlogo.png",
    "state comp.jpg",
    "travelersinsurance.png",
    "zurich1.png"
];
   