import { Component, OnInit } from '@angular/core';
import { SolutionDetail } from '../model/solution.detail';

const DETAILS: SolutionDetail[] = [
  {
    image: "../../assets/img/doctor-with-health-insurance-modern-interface-icon-picture-id905072382.jpg",
    title: "Depending on your needs, our health care packages may include:",
    template: `
      <ul>
        <li>Group Health Insurance (STOP-LOSS, HMO & PPO)</li>
        <li>Dental Coverage</li>
        <li>Vision Coverage</li>
        <li>Life / AD&D</li>
        <li>Disability Insurance</li>
        <li>Medical Expense Insurance
        <li>Medicare Supplements</li>
        <li>Supplemental Insurance</li>
      </ul>
    
  <h5 class="card-title">Other benefit options for your business:</h5>
      <ul>
        <li>401k Plans</li>
        <li>Business Continuation</li>
        <li>Buy/Sell Agreements</li>
        <li>Key Man Insurance </li>
      </ul>
    `
  }
]

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.css']
})
export class BenefitsComponent implements OnInit {
  details = DETAILS;

  constructor() { }

  ngOnInit() {
  }

}
