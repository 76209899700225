import { Component, OnInit } from '@angular/core';
import { SolutionDetail } from '../model/solution.detail';

const DETAILS: SolutionDetail[] = [
  {
    image: "../../assets/img/businessman-opening-envelope-with-paycheck-picture-id1000964802.jpg",
    title: "Our Payroll Services Include:",
   template: `
    <ul>
    <li>Payroll Processing</li>
    <li>Time and Attendance</li>
    <li>All-inclusive Tax Filings</li>
    <li>Web-based Information Access</li>
    </ul>
  
    <h5 class="card-title">Spend Less Time on Administration</h5>
    <h6 class="card-text">Our extensive solutions and service tools allow you to focus more time on your business operations and less time on payroll.</h6>
    
    <h5 class="card-title">Confidence on Compliance</h5>
    <h6 class="card-text">Our service professionals help you remain compliant with regulations and employer obligations regarding tax codes and pay in all States.</h6> 
    
    <h5 class="card-title">ADP Direct Access</h5>
    Apgar & Bullard has a long-standing relationship with ADP.  We can offer substantial discounts on all of their services.  Even if you already subscribe to ADP, we can save you money.
   `
  }
]
@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.css']
})
export class PayrollComponent implements OnInit {
  details = DETAILS;
  constructor() { }

  ngOnInit() {
  }

}
