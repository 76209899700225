import { Component, OnInit } from '@angular/core';
import { SolutionDetail } from '../model/solution.detail';

const DETAILS: SolutionDetail[] = [
    {
      image: "../../assets/img/portrait-of-four-workers-in-carpet-factory-picture-id915681374.jpg",
      title: "Protect Your Business",
      template: `No matter what business you’re in, each workday is dedicated to doing your job well and providing your customers with the best service possible. Apgar & Bullard would like to return the favor by giving you the coverage you need to ensure the security of your business. 
      <p></p>
      <h6 class="card-text">At Apgar & Bullard, we’re committed to keeping your business protected – so you can focus on your day-to-day operations. And with the right insurance plan, your business will be covered from top to bottom.</h6> 
      <h5 class="card-title">
      Commercial Property & Casualty Coverage
      </h5>
      <ul>
      <li>Business Owner’s Policy</li>
      <li>General Liability</li>
      <li>Inland Marine</li>
      <li>Product Liability</li>
      <li>Umbrella & Excess Liability</li>
      <li>Commercial Auto</li>
      <li>EPLI/D&O</li>
      </ul>
      `
    },
    {
      image: "../../assets/img/businessman-presenting-work-safety-concept-hazards-protections-health-picture-id516988096.jpg",
      title: "Workers Compensation",
      template: ` 
      <ul>
        <li>Access to over 30 of the most top-rated carriers.</li>
        <li>Our staff has a collective 50+ years’ experience specializing in workers comp.</li>
        <li>For those eligible- Monthly reporting of payroll (avoid those pesky audits)</li>
        <li>For those who aren’t, we have extensive experience in reconciliation of audits.</li>
        <li>Safety – We offer customized Injury and Illness Prevention Programs as a part of our complete safety solution.</li>
        <li>Claims tracking- We follow claims from day 1 to ensure timely administration, adjustment, and closure.</li>
      </ul>
      `
    }
  ]

@Component({
  selector: 'app-risk-management',
  templateUrl: './risk-management.component.html',
  styleUrls: ['./risk-management.component.css']
})

export class RiskManagementComponent implements OnInit {
  details = DETAILS;
  constructor() {}

  ngOnInit() {
  }

}
