import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { HeaderComponent } from './header/header.component';
import { SolutionDetailComponent } from './solution-detail/solution-detail.component';
import { AppRoutingModule } from './app-routing.module';
import { AboutComponent } from './about/about.component';
import { BillboardComponent } from './billboard/billboard.component';
import { HumanResourcesComponent } from './human-resources/human-resources.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { RiskManagementComponent } from './risk-management/risk-management.component';
import { PayrollComponent } from './payroll/payroll.component';
import { FooterComponent } from './footer/footer.component';
import { QuoteComponent } from './quote/quote.component';
import { ContactComponent } from './contact/contact.component';
import { ConnectionService } from './connection.service';
import {PartnerImageService } from './partner-gallery/partner-image.service';
import { PartnerGalleryComponent } from './partner-gallery/partner-gallery.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LoadScriptDirective } from './load-script.directive';

@NgModule({
  declarations: [
    AppComponent,
    SolutionsComponent,
    HeaderComponent,
    SolutionDetailComponent,
    AboutComponent,
    BillboardComponent,
    HumanResourcesComponent,
    BenefitsComponent,
    RiskManagementComponent,
    PayrollComponent,
    FooterComponent,
    QuoteComponent,
    ContactComponent,
    PartnerGalleryComponent,
    PrivacyComponent,
    LoadScriptDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [ConnectionService, PartnerImageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
