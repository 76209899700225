import { Component, OnInit, Input } from '@angular/core';
import { Solution } from '../model/solution';
import { SOLUTIONS } from '../model/solution.list';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})
export class SolutionsComponent implements OnInit {
  solutions = SOLUTIONS;
  
  constructor() { }

  ngOnInit() {
  }

}
