export enum SubjectType {
    HR = "HRAnswerLink",
    Payroll = "Payroll & Gov't Compliance",
    WorkComp = "Worker's Comp",
    Commercial ="Commercial Insurance",
    Auto = "Auto/Homeowners Insurance",
    Health = "Health Insurance",
    Life = "Life Insurance",
    Disability = "Disability Insurance",
    Group = "Group Medical/Employee Benefits"
}

