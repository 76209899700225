import { Component, OnChanges } from '@angular/core';
import { PartnerImageService } from './partner-image.service';

@Component({
  selector: 'app-partner-gallery',
  templateUrl: './partner-gallery.component.html',
  styleUrls: ['./partner-gallery.component.css']
})
export class PartnerGalleryComponent implements OnChanges {
  allImages:any[] = []; 

  constructor(private partnerImageService: PartnerImageService) { 
    this.allImages = this.partnerImageService.getImages().map(x => "../../assets/img/partners/" + x);
  }
    
  ngOnInit() {
  }

  ngOnChanges() {    
    this.allImages = this.partnerImageService.getImages().map(x => "../../assets/img/partners/" + x);    
  }    

}
