import { Component, OnInit } from '@angular/core';
import { SolutionDetail } from '../model/solution.detail';

const DETAILS: SolutionDetail[] = [
  {
    image: "../../assets/img/successful-businesswoman-picture-id929818248.jpg",
    title: "Introducing HRAnswerLink",
    template: `
        The Apgar & Bullard team is excited to offer you access to our new HR Support center. HRAnswerLink gives you everything you need to make HR Administration easier and provides HR Expertise whenever you need it... 24/7!
        <div></div>
        We also offer an HR On-Demand Program, in which your company can virtually outsource their HR department. Experienced and certified HR Professionals work with you to address your specific questions and needs.
    
        <div class="row justify-content-center">
            <a class="btn btn-outline-success mr-2 my-sm-0"  href="https://apgarbullard.myhrsupportcenter.com/users/sign_in">Login Now</a>
        </div>`
  },
  {
    image: "",
    title: "",
    template: `<ul>
    <li><b>Training On-Demand:</b> A comprehensive library of HR-related trainings available for immediate viewing and updated monthly.</li>
    <li><b>Policy Library:</b> Library of standard business policies.</li>
    <li><b>State and Federal Laws:</b> Written in easy to understand terms.</li>
    <li><b>HR Forms:</b> Library of business, state and federal forms.</li>
    <li><b>3-Minute HR Audit:</b> Assess the effectiveness of your HR functions.</li>
    <li><b>eAlerts:</b> Instant notification for important law updates.</li>
    <li><b>HR Checklists:</b> Checklists to help organize business tasks.</li>
    <li><b>HR Guides:</b> Various guidelines providing helpful references in following particular business procedures.</li>
    <li><b>Benefits:</b> Coverage of core concepts and fundamentals regarding employee benefits.</li>
    <li><b>Job Descriptions:</b> Catalog to help hire and organize employees.</li>
    <li><b>Q&A Database:</b> Hundreds of questions already answered by HR Pros.</li>
    <li><b>HR Best Practices:</b> Monthly articles covering best practices.</li>
    </ul>`
  }
]
@Component({
  selector: 'app-human-resources',
  templateUrl: './human-resources.component.html',
  styleUrls: ['./human-resources.component.css']
})
export class HumanResourcesComponent implements OnInit {
  details = DETAILS;
  constructor() { }

  ngOnInit() {
  }

}
